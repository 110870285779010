<template>
	<div class="school">
		<!-- //轮播图 -->
		<mt-swipe :auto="4000" class="swiper">
			<mt-swipe-item v-for="(item, index) in buesineschool" :key="index">
				<div v-if="item.click_types == 0" @click="toRescue(item.click_parame)">
					<img :src="item.cover_imgurl" />
				</div>
				<div v-if="item.click_types == 1">
					<router-link :to="'/graphicdetails?id=' + item.click_parame">
						<img :src="item.cover_imgurl" />
					</router-link>
					<img :src="item.cover_imgurl" />
				</div>
			</mt-swipe-item>
		</mt-swipe>

		<!-- //新手教程 -->
		<ul class="school-wrap">
			<li v-for="(item, index) in schoolclassify" :key="index">
				<router-link :to="'/newtutorial?id=' + item.cate_id + '&title=' + item.cate_name">{{ item.cate_name }}
				</router-link>
			</li>
		</ul>

		<!-- 文章列表 -->
		<article-list :article="recommendArticle" :finished="finished" :isShow="isShow" @getMore="getMore"
			ref="articleList"></article-list>

		<!-- 客服组件 -->
		<customer-service></customer-service>
	</div>
</template>

<script>
	import {
		BuesinesSchool,
		SchoolClassIfy,
		SchoolRecommend
	} from "@/http/api";
	import CustomerService from "@/views/home/children/CustomerService";
	import ArticleList from "@/components/articles/ArticleList";

	export default {
		components: {
			CustomerService,
			ArticleList
		},
		data() {
			return {
				userToken: "",
				buesineschool: {},
				schoolclassify: {},

				isShow: false,
				isLoad: true,
				pages: 1,
				finished: false,
				recommendArticle: []
			};
		},
		created() {
			document.title = "商学院";
		},
		mounted() {
			//  商学院轮播图
			this.BuesinesSchool();
			//  商学院分类
			this.SchoolClassIfy();
			//  商学院推荐
			this.SchoolRecommend();
		},
		methods: {
			//  跳转到外部链接
			toRescue(url) {
				this.$dialog.confirm({
					message: '点击确认将跳转至外站',
				}).then(() => {
					window.location.href = url;
				}).catch(() => {
					// 取消执行
				});
			},

			//  商学院轮播图
			async BuesinesSchool() {
				const res = await BuesinesSchool();
				if (res.code == 200) {
					this.buesineschool = res.data;
				}
			},

			//  商学院分类
			async SchoolClassIfy() {
				const res = await SchoolClassIfy();
				if (res.code == 200) {
					this.schoolclassify = res.data;
				}
			},

			//  商学院推荐
			async SchoolRecommend() {
				if (this.isLoad) {
					const res = await SchoolRecommend({
						data: {
							pages: this.pages,
							user_token: this.$LStorage.getItem("userToken").user_token
						}
					});
					// console.log(res);
					this.isShow = true;
					this.$refs.articleList.changeLoading(false);
					if (res.numPage == res.data.length) {
						this.pages++;
						this.finished = false;
					} else {
						this.isLoad = false;
						this.finished = true;
					}
					this.recommendArticle = this.recommendArticle.concat(res.data);
				}
			},

			//  点击加载更多
			getMore() {
				this.SchoolRecommend();
			}
		}
	};
</script>

<style lang="scss">
	.school {
		width: 100%;
		min-height: 100vh;
		background: #f6f6f6;
		padding-bottom: 1rem;
		box-sizing: border-box;

		.my-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.swiper {
			height: 3.02rem;
			margin: 0 auto;

			/deep/ .mint-swipe-indicators {
				bottom: 0;
			}

			/deep/ .mint-swipe-indicator {
				background-color: #ea5850;
				opacity: 1;
				width: 0.16rem;
				height: 0.16rem;
			}

			/deep/ .mint-swipe-indicator.is-active {
				background-color: #ffffff;
			}

			img {
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		.school-wrap {
			width: calc(100% - 0.6rem);
			margin: 0 auto;
			margin-top: 0.33rem;
			margin-bottom: 0.1rem;
			display: flex;
			flex-wrap: wrap;

			li {
				width: 32%;
				height: 0.73rem;
				line-height: 0.73rem;
				font-size: 0.23rem;
				color: #fff;
				text-align: right;
				padding-right: 0.25rem;
				box-sizing: border-box;
				margin-bottom: 0.27rem;
				background-size: 100% 0.73rem;
				background-repeat: no-repeat;
				background-position: 0 0;
				margin-right: 0.1rem;

				a {
					font-size: 0.31rem;
				}
			}

			li:nth-child(1) {
				background-image: url("../../assets/sxy01-1.png");
			}

			li:nth-child(2) {
				background-image: url("../../assets/sxy02.png");
			}

			li:nth-child(3) {
				background-image: url("../../assets/sxy03.png");
				margin-right: 0;
			}

			li:nth-child(4) {
				background-image: url("../../assets/sxy04.png");
			}

			li:nth-child(5) {
				background-image: url("../../assets/sxy05.png");
			}

			li:nth-child(6) {
				background-image: url("../../assets/sxy06.png");
				margin-right: 0;
			}
		}
	}
</style>
